import {Injectable} from '@angular/core';
import {FormFieldBase} from './form-field-base';
import {AbstractControl, FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormBlock, FormBlockOptions} from './form-block';
import {FormPropertyBase} from './form-property-base';
import {FormFieldSelect, FormFieldSelectOptions, OptionsAPIOptions} from './form-field-select';
import {FormFieldCheckbox, FormFieldCheckBoxOptions} from './form-field-checkbox';
import {FormFieldInput, FormFieldInputOptions} from './form-field-input';
import {FormFieldTextarea, FormFieldTextareaOptions} from './form-field-textarea';
import {APIService} from '../api.service';
import {TzDatePipe} from '../../@theme/pipes/tz-date.pipe';
import {FilterService} from '../filter.service';
import {TaskTypeField, TaskTypeFields} from '../api.task_types';
import {Observable} from 'rxjs';

export interface APIOptionEndpoint {
    api: OptionsAPIOptions;
    fields: FormFieldBase<any>[];
    res?: any[];
}

export interface FormDefinition<T = any> {
    fields: FormPropertyBase<T>[];
    viewSet?: any;
    conditions?: any;
    beforeSubmit?: (formData) => Observable<any>;
}

@Injectable()
export class FormGroupService {
    static customFieldKeys: string[] = [
        'history_of_present_illness',
        'lab_test_review',
        'treatment_review',
        'event_review',
        'order_review',
        'vital_review',
        'review_of_systems',
        'physical_examination',
        'vital_examination',
        'social_history',
        'family_history',
        'past_medical_hx',
        'problem_list',
        'lab_orders',
        'rx_orders',
        'other_orders',
        'assessment_plan',
        'patient_complexity',
        'visit_details',
        'physician_order',
        'trauma_evaluation',
        'baseline_evaluation',
        'awareness_of_consequences',
        'symptoms_of_illness',
        'mental_status_exam',
        'behavioral_social_history',
        'developmental_history',
        'behavioral_family_history',
        'psychosocial_circumstances',
        'behavioral_review_of_systems',
        'past_psychiatric_history',
        'behavioral_physical_examination',
        'phq_9',
        'bims',
        'cognitive_symptoms_of_illness',
        'cognitive_baseline_evaluation',
        'cognitive_reason_for_visit',
        'cognitive_fast_screening',
        'cognitive_care_plan',
        'questionnaire_responses',
        'demographics',
        'patient_information',
        'procedure_review',
        'imaging_result_review',
        'patient_allergy_review',
        'clinical_test_result_review',
        'immunization_review',
        'wound_reason_for_visit',
        'wound_review',
    ];

    toFormGroup(formFields: FormPropertyBase[]) {
        const group = new UntypedFormGroup({});

        formFields.forEach(field => {
            let control: AbstractControl;
            if (field instanceof FormFieldCheckbox) control = new FormControl<boolean>(!!field.value, [this.checkBoxValidator, ...(field.validators || [])]);
            else if (field instanceof FormFieldSelect && field.multi) control = new UntypedFormControl(field.value, [this.multiSelectValidator, ...(field.required ? [this.multiSelectRequiredValidator] : []), ...(field.validators || [])]);
            else if (field instanceof FormFieldBase) control = new UntypedFormControl(field.value, [...(field.required ? [Validators.required] : []), ...(field.validators || [])]);
            else if (field instanceof FormBlock) control = field.multi ? new UntypedFormArray([], [...(field.required ? [Validators.required] : []), ...(field.validators || [])]) : this.toFormGroup(field.fields);
            if (field instanceof FormBlock && field.multi) field.meta.arrayFormGroupFields = field.fields;
            if (control && !field.deprecated) {
                group.addControl(field.key, control);
                if (field.disabled) group.controls[field.key].disable();
            }
        });
        return group;
    }

    getAPIOptionEndpoints(formFields: FormPropertyBase[], endpoints: any[] = []): APIOptionEndpoint[] {
        formFields.forEach(field => {
            if (field instanceof FormFieldSelect && field.optionsAPI) {
                const endpoint = endpoints.find(x => JSON.stringify(x.api) == JSON.stringify(field.optionsAPI) && x.api.viewSet == field.optionsAPI.viewSet);
                endpoint ? endpoint.fields.push(field) : endpoints.push({api: field.optionsAPI, fields: [field]});
            } else if (field instanceof FormBlock) {
                this.getAPIOptionEndpoints(field.fields, endpoints);
            }
        });
        return endpoints;
    }

    checkBoxValidator(control: AbstractControl) {
        if (control.value !== true && control.value !== false) control.setValue(!!control.value, {onlySelf: true, emitEvent: false, emitModelToViewChange: false, emitViewToModelChange: false});
        return null;
    }

    multiSelectValidator(control: AbstractControl) {
        if (control.value === '' || control.value === null) control.setValue([], {onlySelf: true, emitEvent: false, emitModelToViewChange: false, emitViewToModelChange: false});
        return null;
    }

    multiSelectRequiredValidator(control: AbstractControl) {
        if (!Array.isArray(control.value) || !control.value.length) return {required: true};
        return null;
    }

    static parseFormDefinition(fields: TaskTypeFields, api: APIService, tzDate: TzDatePipe, filterService: FilterService): FormDefinition {
        return {
            fields: fields.reduce((fields, fieldOptions) => fields.concat(this.parseFormField(fieldOptions, api, tzDate, filterService)), []),
        };
    }

    static parseFormField(fieldOptions: TaskTypeField, api: APIService, tzDate: TzDatePipe, filterService: FilterService): FormPropertyBase | FormPropertyBase[] {
        const parsedOptions = FormGroupService.parseFormFieldOptions(fieldOptions, api, tzDate, filterService);
        if (FormGroupService.customFieldKeys.includes(fieldOptions.key as string)) {
            return new FormFieldInput(parsedOptions as FormFieldInputOptions);
        } else if (fieldOptions.type == 'field_set') {
            if (fieldOptions.fields) {
                const fields: FormPropertyBase[] = fieldOptions.fields.reduce((fields, fieldOptions) => fields.concat(this.parseFormField(fieldOptions, api, tzDate, filterService)), []);
                return parsedOptions.key ? new FormBlock({...parsedOptions, fields} as FormBlockOptions) : fields;
            }
        } else {
            switch (fieldOptions.type) {
                case 'text':
                case 'number':
                case 'date':
                case 'datetime':
                    return new FormFieldInput(parsedOptions as FormFieldInputOptions);
                case 'textarea':
                    return new FormFieldTextarea(parsedOptions as FormFieldTextareaOptions);
                case 'checkbox':
                    return new FormFieldCheckbox(parsedOptions as FormFieldCheckBoxOptions);
                case 'select':
                case 'foreign_key_select':
                    return new FormFieldSelect(parsedOptions as FormFieldSelectOptions);
            }
        }
    }

    static parseFormFieldOptions(fieldOptions: TaskTypeField, api: APIService, tzDate: TzDatePipe, filterService: FilterService): FormFieldCheckBoxOptions | FormFieldInputOptions | FormFieldTextareaOptions | FormFieldSelectOptions | FormBlockOptions {
        const opts: any = {};
        if (fieldOptions.label) opts.label = fieldOptions.label;
        if (fieldOptions.key) opts.key = fieldOptions.key;
        if (fieldOptions.icon) opts.icon = fieldOptions.icon;
        if (fieldOptions.type == 'number') opts.type = 'number';
        if (fieldOptions.type == 'date') opts.controlType = 'date';
        if (fieldOptions.type == 'datetime') opts.controlType = 'datetime';
        // if (fieldOptions.options_type) opts.key = fieldOptions.key;
        if (fieldOptions.choices) opts.options = fieldOptions.choices;
        opts.disabled = !!fieldOptions.disabled;
        opts.deprecated = !!fieldOptions.deprecated;
        if (fieldOptions.multiple) opts.multi = true;
        if (fieldOptions.placeholder) opts.placeholder = fieldOptions.placeholder;
        if (fieldOptions.hint) opts.hint = fieldOptions.hint;
        if (fieldOptions.style) opts.style = fieldOptions.style;

        // TODO DYNAMIC FORMS: implement foreign keys
        // if (fieldOptions.foreign_key_options) {
        //     const fko = fieldOptions.foreign_key_options;
        //     const optionsAPI: OptionsAPIOptions = {
        //         viewSet: api[fko.viewset],
        //         listMethod: fko.list_method,
        //         filtersFunction: contextData => {
        //             const filters = {};
        //             if (fko.filters_fix) {
        //                 Object.assign(filters, ...fko.filters_fix);
        //             }
        //             if (fko.filters_fields) {
        //                 fko.filters_fields.forEach(key => filters[key] = getId(contextData[key]));
        //             }
        //             return filters;
        //         }
        //     };
        //     switch (fko.name) {
        //         case 'FOREIGN_KEY_ICD_CODES':
        //             optionsAPI.nameFunction = x => `${toIcdFormat(x.name)} - ${x.description}`;
        //             opts.asyncOptionsAPI = optionsAPI;
        //             break;
        //         case 'FOREIGN_KEY_LAB_TEST_TYPE':
        //             opts.asyncOptionsAPI = optionsAPI;
        //             break;
        //         case 'FOREIGN_KEY_PATIENT_EVENTS':
        //             optionsAPI.nameFunction = x => `${tzDate.transform(x.date)} - ${x.type}`;
        //             opts.optionsAPI = optionsAPI;
        //             break;
        //         case 'FOREIGN_KEY_PATIENT_LAB_REPORTS':
        //             optionsAPI.nameFunction = x => `${tzDate.transform(x.resulted_date)} - ${x.description}`;
        //             opts.optionsAPI = optionsAPI;
        //             break;
        //         case 'FOREIGN_KEY_PATIENT_LAB_TESTS':
        //             // TODO: implement parent lab report's date
        //             optionsAPI.nameFunction = x => `${tzDate.transform(x.created_at)} - ${x.name}`;
        //             opts.optionsAPI = optionsAPI;
        //             break;
        //         case 'FOREIGN_KEY_PATIENT_CRITICAL_VITALS':
        //             optionsAPI.nameFunction = x => `${tzDate.transform(x.recorded)} - ${filterService.medicalResultTypes.find(type => type.id === x.medical_result_type).name}`;
        //             opts.optionsAPI = optionsAPI;
        //             break;
        //         case 'FOREIGN_KEY_TREATMENT_HISTORY':
        //             optionsAPI.nameFunction = x => `${tzDate.transform(x.start_date)} - ${x.drug_name ? x.drug_name.name : x.drug} ${TreatmentEntry.amountText(x)}`;
        //             opts.optionsAPI = optionsAPI;
        //             break;
        //     }
        // }

        // if (fieldOptions.regex) { // TODO: Implement regex option if needed
        //
        // }
        if (fieldOptions.required) opts.required = true;

        return opts;
    }
}
